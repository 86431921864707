import { MAP_DEFAULT_LAT_DISTANCE, MAP_DEFAULT_LNG_DISTANCE, TENANT_NAME } from '../constants';
import { GeometryType, PublicDomainType } from '../enums';
import { IAddress, IGeometry, IPublicDomainGeometry, IPublicDomainIntake, TCarFreeZone } from '../../types';
import { getCarFreeZoneByGisId } from './requestUtils';
import { LatLngTuple, latLng } from 'leaflet';
import { area, length } from '@turf/turf';

export function addressToString(address: IAddress): string {
  return (
    `${address.street}${address.streetNumber ? ' ' + address.streetNumber : ''},` +
    `${address.zipCode ? address.zipCode + ' ' : ''}${TENANT_NAME}`
  );
}

export function getEmptyGeometryForIntake(intake: IPublicDomainIntake): IPublicDomainGeometry {
  switch (intake?.type?.type) {
    case PublicDomainType.MinorConstruction:
      return { type: GeometryType.Polygon };
    case PublicDomainType.CarfreeZone:
      return { type: GeometryType.Point };
    case PublicDomainType.ParkingBan:
    default:
      return { type: GeometryType.LineString };
  }
}

export function getGeometryForIntake(intake: IPublicDomainIntake): IPublicDomainGeometry {
  return intake.geometry?.type ? intake.geometry : getEmptyGeometryForIntake(intake);
}

export function intakeToAddresses(intake: IPublicDomainIntake): IAddress[] {
  const addresses: IAddress[] = [];
  const { street, streetNumberFrom, streetNumberTo, zipCode, streetNumberUnknown } = intake;

  if (street && streetNumberUnknown) {
    addresses.push({ street, city: TENANT_NAME, streetNumber: '', zipCode });
  } else {
    if (street && streetNumberFrom) {
      addresses.push({ street, city: TENANT_NAME, streetNumber: streetNumberFrom, zipCode });
    }
    if (street && streetNumberTo) {
      addresses.push({ street, city: TENANT_NAME, streetNumber: streetNumberTo, zipCode });
    }
  }

  return addresses;
}

export function intakeToAddressString(intake: IPublicDomainIntake, zones: TCarFreeZone[] = []): string {
  if (intake.type.type === PublicDomainType.CarfreeZone && getCarFreeZoneByGisId(zones, intake.gisId)) {
    return getCarFreeZoneByGisId(zones, intake.gisId)!.name;
  }

  if (intake.street && intake.streetNumberFrom) {
    return (
      `${intake.street} ${intake.streetNumberFrom}` +
      (intake.streetNumberTo && intake.streetNumberFrom !== intake.streetNumberTo ? '-' + intake.streetNumberTo : '') +
      `, ${TENANT_NAME}`
    );
  }

  if (intake.street) {
    return `${intake.street}, ${TENANT_NAME}`;
  }

  return '';
}

export function isIntakeAddressCompleteForGeoCoding(intake: IPublicDomainIntake, withStreetFlag?: boolean): boolean {
  return (
    !!intake.street &&
    !!intake.type &&
    (!withStreetFlag || !!intake.streetFlag) &&
    (!!intake.streetNumberUnknown ||
      (!!intake.streetNumberFrom && (intake.type.type === PublicDomainType.CarfreeZone || !!intake.streetNumberTo)))
  );
}

export function isIntakeValid(intake: IPublicDomainIntake): boolean {
  const completeForGeoCoding = isIntakeAddressCompleteForGeoCoding(intake);

  switch (intake.type.type) {
    case PublicDomainType.CarfreeZone:
      return completeForGeoCoding && !!intake.geometry?.coordinates && !!intake.gisId;
    case PublicDomainType.MinorConstruction:
      return completeForGeoCoding && intake.geometry?.coordinates && intake.conditions && intake.attachments
        ? intake.attachments.length > 0
        : false;
    default:
      return completeForGeoCoding && !!intake.geometry?.coordinates;
  }
}

export function expandLineCoordsToRectangle(coordinates: LatLngTuple[]): [LatLngTuple[]] {
  if (coordinates) {
    const [x1, y1] = coordinates[0];
    let [x2, y2] = coordinates[1];

    // Fix equal coordinates
    if (x1 === x2 && y1 === y2) {
      x2 += 0.0001;
      y2 += 0.0001;
    }

    // First calculate the deltas
    const dx = x2 - x1;
    const dy = y2 - y1;

    // Calculate the radius of the circle (the distance between point 1 and 2)
    const r = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) * 0.5;

    // Math Calculate the angle
    const m = dy / dx;
    const a = (Math.atan(m) + Math.PI / 2) * (1.18 * (m > 0 ? 1 : -1));

    // Calculate the point on the circle
    const x3 = x1 + r * Math.cos(a);
    const y3 = y1 + r * Math.sin(a);

    const x4 = x3 + dx;
    const y4 = y3 + dy;

    return [
      [
        [x1, y1],
        [x2, y2],
        [x4, y4],
        [x3, y3],
        [x1, y1],
      ],
    ];
  }
  return [[]];
}

export const createNewCoordinates = (coord: LatLngTuple): LatLngTuple => [
  coord[0] - MAP_DEFAULT_LAT_DISTANCE,
  coord[1] - MAP_DEFAULT_LNG_DISTANCE,
];

export const calculateInitialPolygon = (coord?: LatLngTuple): LatLngTuple[][] | undefined => {
  if (!coord) return undefined;
  const coordinates: LatLngTuple[] = [coord, createNewCoordinates(coord)];
  return expandLineCoordsToRectangle(coordinates);
};

export const calculateInitialLineString = (coord?: LatLngTuple): LatLngTuple[] | undefined => {
  if (!coord) return undefined;
  return [coord, createNewCoordinates(coord)];
};

export const calculateDistanceBetweenTwoPoints = (coordinates?: LatLngTuple[]): number => {
  if (!coordinates || coordinates?.length < 2) {
    return 0;
  }

  const start = latLng(coordinates[0][0], coordinates[0][1]);
  return start.distanceTo(latLng(coordinates[1][0], coordinates[1][1]));
};

export const getDistance = (geometry?: IGeometry) =>
  geometry
    ? Math.round(
        length(
          {
            geometry,
            type: 'Feature',
            properties: { key: 0, id: 0, type: '', current: true, description: '', title: '' },
          },
          { units: 'meters' },
        ),
      )
    : 0;

export const getArea = (geometry?: IGeometry) =>
  geometry
    ? Math.round(
        area({
          geometry,
          type: 'Feature',
          properties: { key: 0, id: 1, type: '', current: true, description: '', title: '' },
        }),
      )
    : 0;
